import $, { Hook } from "untrue";

import DocumentContext from "../../context/DocumentContext";

import RichText from "../RichText";

function Text({ postId }) {
  const { text, mentionIds } = Hook.useContext(DocumentContext, () => {
    const documents = DocumentContext.getDocuments();

    const { text, mentions: mentionIds } = documents.Post[postId];

    return { text, mentionIds };
  });

  return $(RichText, {
    linkStyle: "color-primary-100 hover:text-decoration-underline",
    text,
    mentionIds,
  });
}

export default Text;
