"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Component = void 0;
const Stateful_1 = require("./Stateful");
const UpdatePromise_1 = require("./UpdatePromise");
class Component extends Stateful_1.Stateful {
    constructor(props) {
        super();
        this.prevProps = null;
        this.nextProps = null;
        this.mounted = false;
        this.unmounted = false;
        this.props = props;
    }
    update() {
        if (this.unmounted) {
            return new UpdatePromise_1.UpdatePromise(false);
        }
        return super.update();
    }
    updateState(state) {
        if (this.unmounted) {
            return new UpdatePromise_1.UpdatePromise(false);
        }
        return super.updateState(state);
    }
    startUpdate() {
        const self = this;
        self.emit("rerender");
    }
    updateProps(props) {
        this.nextProps = props;
        this.performUpdate();
    }
    replaceUpdate() {
        super.replaceUpdate();
        this.prevProps = this.props;
        if (this.nextProps !== null) {
            this.props = this.nextProps;
        }
        this.nextProps = null;
    }
    triggerRender(listener) {
        const self = this;
        self.off("rerender");
        self.on("rerender", listener);
        if (!this.mounted) {
            this.triggerMount();
        }
        else {
            this.triggerUpdate();
        }
        this.emit("render");
    }
    triggerMount() {
        this.mounted = true;
        this.emit("mount");
    }
    triggerUnmount() {
        const self = this;
        self.off("rerender");
        this.mounted = false;
        this.unmounted = true;
        this.settleNextUpdate(false);
        this.emit("unmount");
    }
    render() {
        return [];
    }
}
exports.Component = Component;
exports.default = Component;
