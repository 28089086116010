"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdatePromise = void 0;
const Emitter_1 = require("../Emitter");
class UpdatePromise {
    constructor(value) {
        this.value = value;
        this.emitter = new Emitter_1.Emitter();
        this.settled = false;
    }
    done(listener) {
        this.emitter.off("done");
        this.emitter.on("done", listener);
        if (this.value === true) {
            this.settle(this.value);
        }
        return this;
    }
    failed(listener) {
        this.emitter.off("failed");
        this.emitter.on("failed", listener);
        if (this.value === false) {
            this.settle(this.value);
        }
        return this;
    }
    settle(value) {
        if (this.settled) {
            return;
        }
        this.settled = true;
        this.emitter.emit(value ? "done" : "failed");
    }
}
exports.UpdatePromise = UpdatePromise;
