"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hook = void 0;
const Comparer_1 = require("../Comparer");
const Ref_1 = require("../Ref");
const Var_1 = require("../Var");
const Effect_1 = require("./Effect");
class Hook {
    static useUpdate() {
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const update = () => {
            return hookster.update();
        };
        hookster.set(index, null);
        hookster.increment();
        return update;
    }
    static useState(value) {
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const tmpValue = hookster.has(index) ? hookster.get(index) : value;
        const updateValue = (value) => {
            return hookster.updateValue(index, value);
        };
        hookster.set(index, tmpValue);
        hookster.increment();
        return [tmpValue, updateValue];
    }
    static useRef(value = null) {
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const ref = hookster.has(index) ? hookster.get(index) : new Ref_1.Ref(value);
        hookster.set(index, ref);
        hookster.increment();
        return ref;
    }
    static useVar(value) {
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const tmpVar = hookster.has(index)
            ? hookster.get(index)
            : new Var_1.Var(value);
        hookster.set(index, tmpVar);
        hookster.increment();
        return tmpVar;
    }
    static useMemo(closure, params = []) {
        var _a;
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const prevMemo = (_a = hookster.get(index)) !== null && _a !== void 0 ? _a : null;
        let memo = null;
        if (prevMemo !== null) {
            const equal = Comparer_1.Comparer.compare(params, prevMemo.params);
            if (equal) {
                memo = { value: prevMemo.value, params };
            }
        }
        if (memo === null) {
            memo = { value: closure(), params };
        }
        hookster.set(index, memo);
        hookster.increment();
        return memo.value;
    }
    static useEffect(closure, params = null) {
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const effect = new Effect_1.Effect(closure, params);
        hookster.addEffect(effect);
        hookster.set(index, null);
        hookster.increment();
    }
    static useAsync(closure, params = null) {
        const hookster = this.activeHookster;
        if (hookster === null) {
            throw new Error("Hook not available.");
        }
        const index = hookster.index;
        const tmpClosure = () => {
            closure();
        };
        const effect = new Effect_1.Effect(tmpClosure, params);
        hookster.addEffect(effect);
        hookster.set(index, null);
        hookster.increment();
    }
    static useContext(context, ...selectors) {
        const contexts = Array.isArray(context) ? context : [context];
        const update = Hook.useUpdate();
        const result = Hook.selectContext(selectors);
        Hook.useEffect(() => {
            let timeout;
            const listener = () => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    const tmpResult = Hook.selectContext(selectors);
                    const equal = Comparer_1.Comparer.compare(result, tmpResult);
                    if (equal) {
                        return;
                    }
                    update();
                });
            };
            for (const context of contexts) {
                context.on("update", listener);
            }
            return () => {
                for (const context of contexts) {
                    context.off("update", listener);
                }
            };
        });
        return result;
    }
    static selectContext(selectors) {
        return selectors.reduce((result, selector) => {
            if (result === null) {
                return null;
            }
            const tmpResult = selector(result);
            if (tmpResult === null) {
                return null;
            }
            const mergedResult = Object.assign(Object.assign({}, result), tmpResult);
            return mergedResult;
        }, {});
    }
    static useAnimation(animation, listener) {
        const listenerVar = Hook.useVar(listener);
        Hook.useEffect(() => {
            animation.on("update", listener);
            return () => {
                animation.off("update", listener);
            };
        }, []);
        Hook.useEffect(listenerVar.current);
    }
}
exports.Hook = Hook;
Hook.activeHookster = null;
