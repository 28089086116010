import $ from "untrue";

import TranslationContext from "../../../context/TranslationContext";

function Error({ onRetry }) {
  return $(
    "div",
    {
      class: "h-100% flex justify-center align-center",
    },
    $("div", { class: "flex flex-column align-center" }, [
      $(
        "span",
        { class: "text-center color-secondary-60 mb-15" },
        TranslationContext.getData().template.auth.friends.error
      ),
      $(
        "button",
        {
          class:
            "px-10 py-5 color-white rounded-6 bg-color-primary-100 hover:bg-color-[primary-light]-100 transition-all-300ms",
          onclick: onRetry,
        },
        TranslationContext.getData().buttons.retry
      ),
    ])
  );
}

export default Error;
