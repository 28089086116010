import { Context } from "untrue";

class CounterContext extends Context {
  constructor() {
    super();

    this.state = {
      chatsCount: null,
      notificationsCount: null,
      transactionsCount: null,
      totalCount: null,
    };
  }

  define(key, value) {
    this.updateState({ [key]: value }).done(() => {
      const { chatsCount, notificationsCount, transactionsCount } = this.state;

      if (
        chatsCount !== null &&
        notificationsCount !== null &&
        transactionsCount !== null
      ) {
        this.updateState({
          totalCount: chatsCount + notificationsCount + transactionsCount,
        });
      }
    });
  }
}

export default new CounterContext();
