import $, { Hook } from "untrue";

import { Router } from "@untrue/web";

import AuthContext from "../../context/AuthContext";
import DocumentContext from "../../context/DocumentContext";
import RequestContext from "../../context/RequestContext";

import Header from "./Header";
import Text from "./Text";
import FigureWrapper from "./FigureWrapper";
import FooterButton from "./FooterButton";

function Post({ postId }) {
  const {
    isAuth,
    type,
    text,
    originalText,
    figuresCount,
    likesCount,
    commentsCount,
    sharesCount,
    savesCount,
    coinsCount,
    liked,
    saved,
    originalId,
  } = Hook.useContext(
    [AuthContext, DocumentContext, RequestContext],
    () => {
      const { isAuth } = AuthContext.getState();

      return { isAuth };
    },
    () => {
      const documents = DocumentContext.getDocuments();

      const {
        type,
        text,
        figures: postFigures,
        likesCount,
        commentsCount,
        sharesCount,
        savesCount,
        liked,
        saved,
        original: originalId,
      } = documents.Post[postId];

      let originalText = null;
      let originalFigures = [];

      if (originalId !== null) {
        ({ text: originalText, figures: originalFigures } =
          documents.Post[originalId]);
      }

      const figuresCount =
        originalId === null ? postFigures.length : originalFigures.length;

      return {
        type,
        text,
        originalText,
        figuresCount,
        likesCount,
        commentsCount,
        sharesCount,
        savesCount,
        liked,
        saved,
        originalId,
      };
    }
  );

  const onLike = () => {
    if (!isAuth) {
      Router.pushState(null, "", "/");

      return;
    }

    if (liked) {
      RequestContext.request(null, { unlike: { objectId: postId } });
    } else {
      RequestContext.request(null, { like: { objectId: postId } });
    }
  };

  return $(
    "div",
    { class: "flex flex-column bg-color-secondary-15 rounded-10" },
    [
      $(Header, { postId, small: false }),
      text !== null
        ? $("div", { class: "px-20 pb-20" }, $(Text, { postId }))
        : null,
      type === "share"
        ? $(
            "div",
            {
              class: `px-20 ${figuresCount === 0 ? "mb-20" : ""}`,
            },
            $(
              "div",
              {
                class: `border-1 border-solid border-color-secondary-25 ${
                  figuresCount > 0 ? "border-b-0" : ""
                }`,
              },
              originalId !== null
                ? $(null, {}, [
                    $(Header, { postId: originalId, small: true }),
                    originalText !== null
                      ? $(
                          "div",
                          { class: "px-20 pb-20" },
                          $(Text, { postId: originalId })
                        )
                      : null,
                  ])
                : $(
                    "div",
                    { class: "p-20 color-secondary-60" },
                    "Post not available"
                  )
            )
          )
        : null,
      figuresCount > 0
        ? $(
            "div",
            { class: "mb-10" },
            $(FigureWrapper, {
              postId: originalId === null ? postId : originalId,
            })
          )
        : null,
      $("div", { class: "flex justify-between px-10 pb-10" }, [
        $("div", { class: "flex" }, [
          $(FooterButton, {
            icon: "fa-heart",
            iconColor: liked ? "color-red-100" : undefined,
            count: likesCount,
            onClick: onLike,
          }),
          $(FooterButton, {
            icon: "fa-comment-alt",
            count: commentsCount,
            onClick: onLike,
          }),
          $(FooterButton, {
            icon: "fa-share",
            count: sharesCount,
            onClick: onLike,
          }),
          $(FooterButton, {
            icon: "fa-bookmark",
            count: savesCount,
            onClick: onLike,
          }),
        ]),
        $(FooterButton, {
          icon: "fa-dollar-sign",
          onClick: onLike,
        }),
      ]),
    ]
  );
}

export default Post;
