"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hookster = void 0;
const Emitter_1 = require("../Emitter");
const Comparer_1 = require("../Comparer");
const UpdatePromise_1 = require("../Stateful/UpdatePromise");
const Hook_1 = require("./Hook");
class Hookster extends Emitter_1.Emitter {
    constructor() {
        super(...arguments);
        this.index = 0;
        this.prevIndex = null;
        this.values = [];
        this.nextValues = new Map();
        this.effects = [];
        this.prevEffects = [];
        this.hooked = false;
        this.unhooked = false;
        this.updateId = -1;
        this.nextUpdateId = 0;
        this.promises = new Map();
    }
    activate() {
        Hook_1.Hook.activeHookster = this;
        if (!this.hooked) {
            return;
        }
        this.prevIndex = this.index;
        this.index = 0;
        this.prevEffects = this.effects;
        this.effects = [];
        clearTimeout(this.timeout);
        this.updateId = this.nextUpdateId;
        this.nextUpdateId++;
        this.replace();
    }
    deactivate() {
        Hook_1.Hook.activeHookster = null;
        if (this.prevIndex !== null && this.index !== this.prevIndex) {
            throw new Error("Irregular number of hooks.");
        }
    }
    has(index) {
        return index < this.values.length;
    }
    get(index) {
        return this.values[index];
    }
    set(index, value) {
        this.values[index] = value;
    }
    increment() {
        this.index++;
    }
    update() {
        if (this.unhooked) {
            return new UpdatePromise_1.UpdatePromise(false);
        }
        return this.queue();
    }
    updateValue(index, value) {
        if (this.unhooked) {
            return new UpdatePromise_1.UpdatePromise(false);
        }
        if (!this.nextValues.has(index)) {
            const currentValue = this.values[index];
            const equal = Comparer_1.Comparer.compare(value, currentValue);
            if (equal) {
                return new UpdatePromise_1.UpdatePromise(true);
            }
        }
        this.nextValues.set(index, value);
        return this.queue();
    }
    queue() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.emit("rerender");
        });
        const promise = new UpdatePromise_1.UpdatePromise(null);
        let promises = this.promises.get(this.nextUpdateId);
        if (promises === undefined) {
            promises = [];
            this.promises.set(this.nextUpdateId, promises);
        }
        promises.push(promise);
        return promise;
    }
    replace() {
        this.nextValues.forEach((value, key) => {
            this.values[key] = value;
        });
        this.nextValues.clear();
    }
    settlePromises(updateId, value) {
        const promises = this.promises.get(updateId);
        if (promises === undefined) {
            return;
        }
        promises.forEach((promise) => {
            promise.settle(value);
        });
        this.promises.delete(updateId);
    }
    settle(value) {
        this.settlePromises(this.updateId, value);
    }
    settleNext(value) {
        this.settlePromises(this.nextUpdateId, value);
    }
    addEffect(effect) {
        this.effects.push(effect);
    }
    runEffects() {
        this.effects.forEach((effect, i) => {
            var _a;
            const prevEffect = (_a = this.prevEffects[i]) !== null && _a !== void 0 ? _a : null;
            effect.run(prevEffect);
        });
    }
    cleanUpEffects() {
        this.effects.forEach((effect) => {
            effect.cleanUp();
        });
    }
    hook(listener) {
        this.off("rerender");
        this.on("rerender", listener);
        if (!this.hooked) {
            this.hooked = true;
        }
        else {
            this.settle(true);
        }
        this.runEffects();
    }
    unhook() {
        this.off("rerender");
        this.hooked = false;
        this.unhooked = true;
        this.settleNext(false);
        this.cleanUpEffects();
    }
}
exports.Hookster = Hookster;
