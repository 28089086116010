"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stateful = void 0;
const Comparer_1 = require("../Comparer");
const Emitter_1 = require("../Emitter");
const UpdatePromise_1 = require("./UpdatePromise");
class Stateful extends Emitter_1.Emitter {
    constructor() {
        super(...arguments);
        this.prevState = null;
        this.nextState = null;
        this.updateId = -1;
        this.nextUpdateId = 0;
        this.updatePromises = new Map();
    }
    init() { }
    update() {
        return this.queueUpdate();
    }
    updateState(state) {
        if (this.nextState === null) {
            const tmpState = Object.assign(Object.assign({}, this.state), state);
            const equal = Comparer_1.Comparer.compare(tmpState, this.state);
            if (equal) {
                return new UpdatePromise_1.UpdatePromise(true);
            }
        }
        this.nextState = Object.assign(Object.assign(Object.assign({}, this.state), this.nextState), state);
        return this.queueUpdate();
    }
    queueUpdate() {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            this.startUpdate();
        });
        const promise = new UpdatePromise_1.UpdatePromise(null);
        let promises = this.updatePromises.get(this.nextUpdateId);
        if (promises === undefined) {
            promises = [];
            this.updatePromises.set(this.nextUpdateId, promises);
        }
        promises.push(promise);
        return promise;
    }
    performUpdate() {
        clearTimeout(this.updateTimeout);
        this.updateId = this.nextUpdateId;
        this.nextUpdateId++;
        this.replaceUpdate();
    }
    replaceUpdate() {
        this.prevState = this.state;
        if (this.nextState !== null) {
            this.state = this.nextState;
        }
        this.nextState = null;
    }
    settleUpdatePromises(updateId, value) {
        const promises = this.updatePromises.get(updateId);
        if (promises === undefined) {
            return;
        }
        promises.forEach((promise) => {
            promise.settle(value);
        });
        this.updatePromises.delete(updateId);
    }
    settleUpdate(value) {
        this.settleUpdatePromises(this.updateId, value);
    }
    settleNextUpdate(value) {
        this.settleUpdatePromises(this.nextUpdateId, value);
    }
    triggerUpdate() {
        const self = this;
        this.settleUpdate(true);
        self.emit("update");
    }
}
exports.Stateful = Stateful;
