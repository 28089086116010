"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Effect = void 0;
const Emitter_1 = require("../Emitter");
const Comparer_1 = require("../Comparer");
class Effect extends Emitter_1.Emitter {
    constructor(closure, params) {
        super();
        this.closure = closure;
        this.params = params;
        this.cleanup = null;
        this.on("run", () => {
            var _a;
            this.cleanup = (_a = this.closure()) !== null && _a !== void 0 ? _a : null;
        });
        this.on("cleanUp", () => {
            if (this.cleanup === null) {
                return;
            }
            this.cleanup();
        });
    }
    run(prevEffect) {
        if (this.params !== null) {
            if (prevEffect !== null) {
                const equal = Comparer_1.Comparer.compare(this.params, prevEffect.params);
                if (equal) {
                    this.cleanup = prevEffect.cleanup;
                    return;
                }
            }
        }
        if (prevEffect !== null) {
            prevEffect.cleanUp();
        }
        this.emit("run");
    }
    cleanUp() {
        this.emit("cleanUp");
    }
}
exports.Effect = Effect;
