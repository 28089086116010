import $, { Hook } from "untrue";

import { Router } from "@untrue/web";

import Helper from "../../helpers/Helper";

import DocumentContext from "../../context/DocumentContext";
import TranslationContext from "../../context/TranslationContext";

import UserPicture from "../UserPicture";
import UserName from "../UserName";

function Header({ postId, small }) {
  const { userId, permalink, userPermalink, createdAt } = Hook.useContext(
    DocumentContext,
    () => {
      const documents = DocumentContext.getDocuments();

      const { user: userId, permalink, createdAt } = documents.Post[postId];

      const { permalink: userPermalink } = documents.User[userId];

      return { userId, permalink, userPermalink, createdAt };
    }
  );

  const onMenu = () => {};

  const path = permalink.split("/").slice(3).join("/");

  const dateObj = new Date(createdAt);

  const now = new Date();

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  yesterday.setTime(yesterday.getTime() - 1000 * 60 * 60 * 24);

  const daysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  daysAgo.setTime(daysAgo.getTime() - 1000 * 60 * 60 * 24 * 6);

  const isToday =
    dateObj.getDate() === today.getDate() &&
    dateObj.getMonth() === today.getMonth() &&
    dateObj.getFullYear() === today.getFullYear();

  const isYesterday =
    dateObj.getDate() === yesterday.getDate() &&
    dateObj.getMonth() === yesterday.getMonth() &&
    dateObj.getFullYear() === yesterday.getFullYear();

  const isDaysAgo = dateObj.getTime() >= daysAgo.getTime();

  let date = "";

  const time = Helper.formatDateTime(createdAt);

  if (isToday) {
    date = TranslationContext.getData().dates.today;
  } else if (isYesterday) {
    date = TranslationContext.getData().dates.yesterday;
  } else if (isDaysAgo) {
    date = `${TranslationContext.getData().dates.days[dateObj.getDay()]}`;
  } else {
    date = `${
      TranslationContext.getData().dates.monthsLess[dateObj.getMonth()]
    } ${dateObj.getDate()}`;

    if (dateObj.getFullYear() !== now.getFullYear()) {
      date += `, ${dateObj.getFullYear()}`;
    }
  }

  const isHourSingular = [1, 13].includes(dateObj.getHours());

  const dateText = isHourSingular
    ? TranslationContext.getData()
        .dates.parsed.singular.replace("%date%", date)
        .replace("%time%", time)
    : TranslationContext.getData()
        .dates.parsed.plural.replace("%date%", date)
        .replace("%time%", time);

  return $("div", { class: "p-20 pr-15 flex align-center" }, [
    $(UserPicture, { userId, size: small ? "w-35" : "w-45" }),
    $("div", { class: "flex-1 flex flex-column mx-10" }, [
      $(
        "div",
        { class: "flex" },
        $(
          "a",
          {
            class:
              "px-10 py-5 rounded-10 hover:bg-color-secondary-20 transition-300ms",
            href: userPermalink,
            onclick: Router.onClick,
          },
          $(UserName, { userId })
        )
      ),
      $("div", { class: "mx-10 color-secondary-60 font-size-14" }, [
        $(
          "a",
          {
            class: "hover:text-decoration-underline",
            href: path,
            onclick: Router.onClick,
          },
          dateText
        ),
      ]),
    ]),
    $(
      "button",
      {
        class:
          "w-30 h-30 flex justify-center align-center font-size-18 color-secondary-60 rounded-100% transition-all-300ms hover:bg-color-secondary-20",
        onclick: onMenu,
      },
      $("i", { class: "fas fa-ellipsis-v" })
    ),
  ]);
}

export default Header;
