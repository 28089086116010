import $, { Hook, Wrapper } from "untrue";

import DocumentContext from "../../context/DocumentContext";

import Figure from "./Figure/Figure";

function FigureWrapper({ postId }) {
  const { liked, figureIds, figuresCount, widths, heights } = Hook.useContext(
    DocumentContext,
    () => {
      const documents = DocumentContext.getDocuments();

      const { liked, figures: figureIds } = documents.Post[postId];

      const figuresCount = figureIds.length;

      const figures = figureIds.map(
        (figureId) => documents.PostFigure[figureId]
      );

      const mediaIds = figures.map((figure) => figure.media);

      const medias = mediaIds.map((mediaId) => documents.Media[mediaId]);

      const widths = medias.map((media) => media.width);

      const heights = medias.map((media) => media.height);

      return { liked, figureIds, figuresCount, widths, heights };
    }
  );

  const [index, updateIndex] = Hook.useState(0);

  const onIndex = (index) => {
    updateIndex(index);
  };

  const onNext = () => {
    onIndex(index + 1);
  };

  const onPrev = () => {
    onIndex(index - 1);
  };

  return [
    $(
      "div",
      {
        class: "w-100% overflow-hidden",
        style: `aspect-ratio: ${widths[index] / heights[index]}`,
      },
      $(
        "div",
        {
          class: "white-space-nowrap vertical-align-top transition-all-300ms",
          style: `transform: translateX(${-index * 100}%)`,
        },
        figureIds.map((figureId, i) =>
          $(
            "div",
            {
              key: figureId,
              class: "inline-block vertical-align-top w-100%",
            },
            $(Figure, {
              figureId,
              index,
              active: i === index,
              figuresCount,
              onNext,
              onPrev,
            })
          )
        )
      )
    ),
    figuresCount > 1
      ? $(
          "div",
          { class: "flex justify-center" },
          figureIds.map((figureId, i) =>
            $(
              "button",
              {
                key: figureId,
                class: "px-5 py-10",
                onclick: () => onIndex(i),
              },
              $(
                "div",
                {
                  class: `relative bg-color-secondary-50 w-8 h-8 rounded-100% transition-all-300ms ${
                    i === index ? "scale-1.25" : ""
                  }`,
                },
                $("div", {
                  class: `absolute inset-0 bg-color-primary-100 rounded-100% transition-all-300ms ${
                    i === index ? "opacity-100%" : "opacity-0%"
                  }`,
                })
              )
            )
          )
        )
      : null,
  ];
}

export default FigureWrapper;
