import $, { Animation, Hook } from "untrue";

import ViewMediaModalContext from "../../context/ViewMediaModalContext";

import Button from "./Button";
import Image from "./Image";
import Video from "./Video";

function ViewMediaModalContent({ item: { source, type, duration } }) {
  const modalRef = Hook.useRef();
  const closeButtonRef = Hook.useRef();

  const animation = Hook.useMemo(() => new Animation(0));

  Hook.useAnimation(animation, () => {
    const modal = modalRef.current;
    const closeButton = closeButtonRef.current;

    modal.style.opacity = animation.interpolate([0, 1], ["0%", "100%"]);

    closeButton.style.transform = `translateX(${animation.interpolate(
      [0, 1],
      ["100%", "0%"]
    )})`;
  });

  Hook.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.removeAttribute("style");
    };
  }, []);

  Hook.useEffect(() => {
    onOpen();
  }, []);

  const onOpen = () => {
    animation.animate(1, 250);
  };

  const onClose = () => {
    const transition = animation.animate(0, 250);

    transition.on("end", () => {
      ViewMediaModalContext.clear();
    });
  };

  return $(
    "div",
    {
      ref: modalRef,
      class:
        "fixed z-20 inset-0 flex justify-center align-center p-60 bg-color-black absolute inset-0",
    },
    [
      type === "image" ? $(Image, { source }) : $(Video, { source, duration }),
      $(
        "div",
        { ref: closeButtonRef, class: "absolute top-5 right-5" },
        $(Button, { icon: "fa-xmark", onClick: onClose })
      ),
    ]
  );
}

function ViewMediaModal({ ...props }) {
  const { item } = Hook.useContext(ViewMediaModalContext, () => {
    const { item } = ViewMediaModalContext.getState();

    return { item };
  });

  if (item === null) {
    return null;
  }

  return $(ViewMediaModalContent, { ...props, item });
}

export default ViewMediaModal;
