"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Persistor = void 0;
const Emitter_1 = require("./Emitter");
const Component_1 = require("./Stateful/Component");
class Persistor extends Emitter_1.Emitter {
    constructor(contexts, Storage, options) {
        var _a, _b, _c;
        super();
        this.Storage = Storage;
        this.contexts = contexts;
        this.name = (_a = options === null || options === void 0 ? void 0 : options.name) !== null && _a !== void 0 ? _a : "app";
        this.version = (_b = options === null || options === void 0 ? void 0 : options.version) !== null && _b !== void 0 ? _b : 0;
        this.migrations = (_c = options === null || options === void 0 ? void 0 : options.migrations) !== null && _c !== void 0 ? _c : {};
        const self = this;
        this.Provider = class PersistorProvider extends Component_1.Component {
            init() {
                this.state = { loading: true, error: false };
                this.on("mount", () => __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield self.init();
                    }
                    catch (error) {
                        this.updateState({ error: true });
                        throw error;
                    }
                    finally {
                        this.updateState({ loading: false });
                    }
                }));
            }
            render() {
                const { loadingChildren = null, errorChildren = null, children, } = this.props;
                const { loading, error } = this.state;
                if (error) {
                    return errorChildren;
                }
                if (loading) {
                    return loadingChildren;
                }
                return children;
            }
        };
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const content = yield this.read();
            let data = {};
            if (content !== null) {
                data = this.migrate(content.data, content.version);
            }
            this.bindContexts();
            this.hydrateContexts(data);
            yield this.persist();
            this.emit("init");
        });
    }
    bindContexts() {
        let timeout;
        const listener = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                this.persist();
            });
        };
        for (const key in this.contexts) {
            const context = this.contexts[key];
            context.on("update", listener);
        }
    }
    hydrateContexts(data) {
        for (const key in this.contexts) {
            const context = this.contexts[key];
            if (key in data) {
                const value = data[key];
                context.hydrate(value);
            }
        }
    }
    persist() {
        return __awaiter(this, void 0, void 0, function* () {
            const content = { data: {}, version: this.version };
            for (const key in this.contexts) {
                const context = this.contexts[key];
                content.data[key] = context.persist();
            }
            yield this.write(content);
        });
    }
    read() {
        return __awaiter(this, void 0, void 0, function* () {
            const { Storage } = this;
            const value = yield Storage.getItem(this.name);
            const content = value !== null ? JSON.parse(value) : null;
            return content;
        });
    }
    write(content) {
        return __awaiter(this, void 0, void 0, function* () {
            const { Storage } = this;
            const value = JSON.stringify(content);
            yield Storage.setItem(this.name, value);
        });
    }
    migrate(data, version) {
        const keys = Object.keys(this.migrations)
            .map((key) => parseInt(key))
            .filter((key) => key > version && key <= this.version);
        return keys.reduce((value, key) => {
            const migrate = this.migrations[key];
            return migrate(value);
        }, data);
    }
}
exports.Persistor = Persistor;
